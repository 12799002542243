<template>
    <div class="offset" >
        <Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
            <div >
                <transition name="slide-fade">
                    <img loading="lazy" v-if="animation1" class="img-horizontal "
                             src="../assets/768x1024/Foto_Yipi.png"/>
                </transition>
            </div>
        </Intersect> 

    <Intersect @enter.once="animation2=true" @leave.once="animation2=false" :threshold="[1.0]">
        <div class="height-div">
            <transition name="slide-fade">
                <div v-if="animation2">  
                    <p class="margin-right-left-1 title-2 text-center">Lo que ofrecemos</p>
                    <p class="margin-right-left-1 subtitle-3" >Un servicio de minimovilidad, cuyo valor está en: </p>
                    <GaleriaOfrecemos/>
                   <!-- <ul class="flex-container-row" v-if="animation2">
                        <li class=" flex-item-column">
                            <img loading="lazy" class=" img-icon" src="../assets/320x480/01_Icono.png"/>  
                            <img  loading="lazy" class="img-boton" src="../assets/320x480/01_Boton_Seguridad.png"/>
                        </li>
                        <li class=" flex-item-column">
                            <img loading="lazy" class=" img-icon" src="../assets/320x480/02_Icono.png"/>  
                            <img loading="lazy" class="img-boton" src="../assets/320x480/02_Boton_Sustentabilidad.png"/>
                        </li>
                        <li class="flex-item-column">
                            <img loading="lazy" class=" img-icon" src="../assets/320x480/03_Icono.png"/>  
                            <img  loading="lazy" class="img-boton" src="../assets/320x480/03_Boton_Accesibilidad.png"/>
                        </li>
                        <li class="flex-item-column">
                            <img loading="lazy" class=" img-icon" src="../assets/320x480/04_Icono.png"/>  
                            <img loading="lazy" class="img-boton" src="../assets/320x480/04_Boton_Conectividad.png"/>
                        </li>
                        <li class="flex-item-column">
                            <img  loading="lazy" class=" img-icon" src="../assets/320x480/05_Icono.png"/>  
                            <img loading="lazy" class="img-boton" src="../assets/320x480/05_Boton_Digitalizacion.png"/>
                        </li>
                    </ul>     -->          
                </div>
            </transition>
        </div>
    </Intersect>
    </div>
</template>

<style >
 @import '../styles/ofrecemos.css';
</style>
<script>
  import Intersect from 'vue-intersect'
 import GaleriaOfrecemos from './GaleriaOfrecemos.vue'
export default {
     components:  {Intersect,GaleriaOfrecemos}, 
     data:()=>({
        animation1:false,
        animation2:false,
     }),
}
</script>