<template>

  <div class="height-div">
    <transition name="slide-fade">
      <div id="aliansas" class="container-medium" > 

        <swiper class="swiper-slide" :options="swiperOption"  >

            <swiper-slide  >  
                <div class="flex-item-column-test" @click="showDescription_01()">
                    <img loading="lazy" class=" img-icon" src="../assets/320x480/01_Icono.png"/> 
                    <div class="box-green" :class="visibility_card_description_1 ? 'show' : 'hidden' ">
                      <img  class="btn-x" src="../assets/320x480/X.png" />
                        <p>Control de  ingresos, usuarios identificados, conductores certificados,
                                                 conductores, usuarios y unidades aseguradas.</p> 
                    </div>
                <img  loading="lazy" class="img-boton-test" src="../assets/320x480/01_Boton_Seguridad.png"/>
         
                </div>     
            </swiper-slide>

            <swiper-slide >  
                <div class="flex-item-column-test" @click="showDescription_02()">
                    <img loading="lazy" class=" img-icon" src="../assets/320x480/02_Icono.png"/>  
                   
                      <div class="box-green" :class="visibility_card_description_2 ? 'show' : 'hidden' ">
                        <img  class="btn-x" src="../assets/320x480/X.png" />
                         <p>Vehículos ecológicos que ahorran 0.6 kg de CO2  viaje de 3 km.</p>
                      </div>
                    <img loading="lazy" class="img-boton-test" src="../assets/320x480/02_Boton_Sustentabilidad.png"/>
                </div>       
            </swiper-slide>

            <swiper-slide >  
                <div class="flex-item-column-test" @click="showDescription_03()">
                      <img loading="lazy" class=" img-icon"   src="../assets/320x480/03_Icono.png"/>  
                      
                      <div class="box-green" :class="visibility_card_description_3 ? 'show' : 'hidden' ">
                        <img  class="btn-x" src="../assets/320x480/X.png" />
                          <p>Cuotas asequibles a través de un esquema de viaje multiusuario.</p>
                      </div>
                        <img loading="lazy" class="img-boton-test" src="../assets/320x480/03_Boton_Accesibilidad.png"/>
                </div>       
            </swiper-slide>

            <swiper-slide >  
                <div class="flex-item-column-test" @click="showDescription_04()">
                    <img loading="lazy" class=" img-icon"   src="../assets/320x480/04_Icono.png"/>  
          
                      <div class="box-green" :class="visibility_card_description_4 ? 'show' : 'hidden' ">
                        <img  class="btn-x" src="../assets/320x480/X.png" />
                          <p>Servicio dentro de polígonos definidos, con el permiso de la Secretaría de Transporte.</p>
                      </div>
                    <img loading="lazy" class="img-boton-test" src="../assets/320x480/04_Boton_Conectividad.png"/>
                </div>       
            </swiper-slide>

            <swiper-slide >  
                <div class="flex-item-column-test" @click="showDescription_05()">
                    <img loading="lazy" class=" img-icon"   src="../assets/320x480/05_Icono.png"/>  
                    
                      <div class="box-green" :class="visibility_card_description_5 ? 'show' : 'hidden' ">
                        <img  class="btn-x" src="../assets/320x480/X.png" />
                          <p>Somos el primer ecosistema tecnológico para la movilidad de cortas distancias.</p>
                      </div>
                    <img loading="lazy" class="img-boton-test" src="../assets/320x480/05_Boton_Digitalizacion.png"/>
                </div>       
            </swiper-slide>
               <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
  
  
      </div>
    </transition>
</div>

</template>


<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

// Import Swiper styles
import "swiper/css/swiper.css";

export default {
  name: "slider-image",
  components: {
    Swiper,
    SwiperSlide,
    
  },
  data() {
    return {
      visibility_card_description_1:false,
      visibility_card_description_2:false,
      visibility_card_description_3:false,
      visibility_card_description_4:false,
      visibility_card_description_5:false,
      animation1:false,
      swiperOption: {
        loop: true,
        speed:1600,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints :{
       
           640:{
                  slidesPerView: 3,
                   spaceBetween: 30,
           }
         },
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
         
      },
    };
  },
  directives: {
    swiper: directive,
  },
  methods: {
    showDescription_01(){
      this.visibility_card_description_1= !this.visibility_card_description_1;
    },
    showDescription_02(){
      this.visibility_card_description_2= !this.visibility_card_description_2;
    },
    showDescription_03(){
      this.visibility_card_description_3= !this.visibility_card_description_3;
    },
    showDescription_04(){
      this.visibility_card_description_4= !this.visibility_card_description_4;
    },
    showDescription_05(){
      this.visibility_card_description_5= !this.visibility_card_description_5;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-x{
  position: absolute;
  width: 15px;
  height: 15px;
  padding: 10px;
  top:0px;
  right: 0px;
  margin-right: 20px;
  margin-top: 13px;

}
.hidden{
  transform: translatey(50px);
  opacity: 0;
  transition: 1.5s;
}
.show{
  opacity: 1;
  transition: 2s;
}
.box-green{
  position: absolute;
  background-image: url("~@/assets/320x480/Rec.png");
  background-size: cover;
  background-position: center;
  height: 200px;
  padding-left: 30px;
  padding-right: 30px;
  width: 280px;
  border-radius: 20px;
    z-index: 1000000;
  display: flex;
  color: white;
  align-items: center;
}
p{
    font-family: "Open Sans";
}
    .flex-item-column-test{
        display: flex;
        flex-direction:column;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
.img-boton-test{
height: 38px;
margin-top: 10px;
width: auto;
object-fit: contain;
}
.img-galery{
  width: 100%;
  height: 100%;
  object-fit: cover;
    background-size: 100% 100%;
}
    .container-medium{
  
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        align-items: center;
        justify-content: center;
    }

.swiper-container {

  height: 300px;

  .swiper-slide {
    display: flex;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
   
    width: 100%;
    height: 250px;
    @media screen and (min-width: 768px) {
    }
    &-active {


      @media screen and (min-width: 768px) {
        width: 500px;
        height: 250px;
      }
    }
  }
}
</style>